import React, { useRef, useState } from "react";
import Layout from "components/layout";
import { CountryArray } from "utils/constants";
import { useForm } from "react-hook-form";
import { useContact } from "hooks/Forms/useContact";
import Loader from "components/loader";
import Modal from "components/popup";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import SEO from "components/seo";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import formCommonPostData from "utils/form-common-post-data";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";

export default function Index() {
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState(false);
  const [phonenumber, setPhonenumber] = useState("");
  const [countrycode, setCountrycode] = useState("1");
  const captchaRef = useRef(null);
  const { status, mutateAsync } = useContact();
  const location = useLocation();
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const [checkBox, setCheckBox] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (token) {
      const postData1 = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        country_id: data.country_id,
        // country_code: countrycode,
        phone: phonenumber,
        company: data.company,
        message: data.message,
        pagelink: location.href,
        consent: data.consent,
      };
      // console.log(postData);
      const postData = { ...postData1, ...formCommonPostData() }
      mutateAsync(postData).then((_) => {
        setOpen(true);
        reset();
      });
    } else {
      alert("Please verify captcha");
    }
  };

  const handleVerify = () => {
    setToken(true);
  };

  return (
    <Layout>
      <SEO
        title="Contact us | Tomedes.com"
        description="Contact us: The Tomedes translators team in USA and worldwide will assist you with any translation issue, translation question or feedback."
        keywords="US translators, translators in Europe, contact translators"
        slug="/contactus.php"
      />
      <Modal open={open} setOpen={setOpen} />
      <div className="bg-midBlue pt-11 pb-14">
        <div className="main_div flex flex-col justify-center px-5 mx-auto font-primary max-w-5xl">
          <div className="lg:w-8/12 lg:p-4 py-4">
            <h1 className="font-secondary text-white font-semibold my-4 py-2 text-45 lg:text-4xl inline-block custom-bg-pos">
              <span className="text-orange">Get in touch</span> with us
            </h1>
            {/* <h2 className="text-white my-4 text-new leading-loose font-opensans">
              We will respond to your message within a few minutes.
              <br />
              You can also contact us on +1 985 239 0142 or +44 (0)16 1509 6140
            </h2> */}
            <h2 className="text-white my-4 text-new leading-loose font-opensans">
              if you have any issues regarding your data , please contact us
              below and we will delete your data
            </h2>
          </div>
          <div className="flex-wrap md:w-full m-auto px-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                  <label
                    className="block tracking-wide  text-white text-lg font-opensans mb-2"
                    htmlFor="first_name"
                  >
                    First Name
                    <span className="text-red">*</span>
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput py-1.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    onClick={() => setCaptchaVisible(true)}
                    placeholder="Your first name"
                    name="first_name"
                    {...register("first_name", { required: true })}
                  />
                  {errors.first_name && (
                    <span className="text-red">{errorMessage}</span>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block  tracking-wide text-white text-lg font-opensans mb-2"
                    htmlFor="last_name"
                  >
                    Last Name
                    <span className="text-red">*</span>
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput py-1.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    placeholder="Your last name"
                    name="last_name"
                    {...register("last_name", { required: true })}
                  />
                  {errors.last_name && (
                    <span className="text-red">{errorMessage}</span>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                  <label
                    className="block  tracking-wide text-white text-lg font-opensans mb-2"
                    htmlFor="email"
                  >
                    Email
                    <span className="text-red">*</span>
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput  py-1.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="email"
                    placeholder="Your email address"
                    name="email"
                    {...register("email", {
                      required: true,
                      pattern: patternEmail,
                    })}
                  />
                  {errors.email && (
                    <span className="text-red">
                      {errorMessageEmail}
                    </span>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3 ">
                  <label
                    className="block  tracking-wide text-white text-lg font-opensans mb-2"
                    htmlFor="phone"
                  >
                    Phone Number
                  </label>
                  <div className="flex flex-col">
                    <IntlTelInput
                      containerClassName="intl-tel-input w-full"
                      inputClassName="appearance-none inline w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput  py-1.5 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onPhoneNumberChange={(
                        status,
                        value,
                        countryData,
                        number,
                        id
                      ) => {
                        setPhonenumber(value);
                      }}
                      onSelectFlag={(num, country) => {
                        setCountrycode(country.dialCode);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-3">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block  tracking-wide text-white text-lg font-opensans mb-2"
                    htmlFor="company"
                  >
                    Company
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput  py-1.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    placeholder="Company"
                    name="company"
                    {...register("company")}
                  />
                  {errors.company && (
                    <span className="text-red">{errorMessage}</span>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block font-opensans  tracking-wide text-white text-lg  mb-2"
                    htmlFor="country_id"
                  >
                    Country
                  </label>
                  <select
                    // {...register("country_id", { required: true })}
                    // register={register("country_id", { required: true })}
                    {...register("country_id")}
                    id="countries"
                    name="country_id"
                    className="appearance-none block w-full bg-white text-gray-700 text-2xl font-opensans border border-grayInput  py-1.5 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option
                      className="text-gray-700 text-2xl font-opensans"
                      disabled
                      selected
                    >
                      Select Country
                    </option>
                    {captchaVisible && CountryArray
                      ? CountryArray.map(({ name }, index) => (
                        <option
                          key={index}
                          value={name}
                          className="text-gray-700 text-2xl font-opensans"
                        >
                          {name}
                        </option>
                      ))
                      : null}
                  </select>
                  {/* {errors.country_id && (
                    <span className="text-red pt-3">{errorMessage}</span>
                  )} */}
                  {errors.country_id && (
                    <span className="text-red pt-3">
                      {errors.country_id.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mt-3 md:mt-0">
                <div className="w-full md:w-full px-3 md:mb-0">
                  <label
                    className="block  tracking-wide text-white text-lg font-opensans mb-2"
                    htmlFor="message"
                  >
                    Tell Us More About Your Needs:
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput  py-1.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    rows="4"
                    placeholder="A member of our team will contact you within a few minutes"
                    name="message"
                    {...register("message")}
                  />
                </div>
              </div>
              {captchaVisible && (
                <HCaptcha
                  sitekey="b6b06718-08ae-457f-a6d7-147046b4c216"
                  onVerify={handleVerify}
                  ref={captchaRef}
                />
              )}
              <div>
                <div className="mb-5 relative">
                  <div className="flex items-baseline gap-2 absolute top-[-10px]">
                    <p className="text-sm text-white font-opensans">
                      *I agree to Tomedes'&nbsp;
                      <Link
                        to="/privacy"
                        className="text-orange hover:text-newOrange-100 underline transition-colors"
                      >
                        Privacy Policy
                      </Link>
                      &nbsp;by submitting this form.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <input type="hidden" id="zc_gad" name="zc_gad" value="" />
                <button
                  type="submit"
                  className="bg-orange text-new text-white py-2 px-10 mb-4 rounded-full mx-auto cursor-pointer font-opensans mt-6"
                >
                  {status === "loading" ? <Loader /> : "SUBMIT"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
